

















import { Component, Vue } from 'vue-property-decorator';
import { LoginAttempt } from '@/apiary/apiary';
import { BvTableFieldArray, BvTableFormatterCallback } from 'bootstrap-vue';
import Axios from 'axios';

@Component
export default class SearchResult extends Vue {
  attempts: LoginAttempt[];

  searchString: string;

  constructor() {
    super();
    this.attempts = [];
    this.searchString = '';
  }

  fields: BvTableFieldArray = [
    {
      key: 'date',
      sortable: true,
      formatter: (value: string): string => {
        const d = new Date(value);
        // This seems stupid...
        return d.toTimeString().split(' ')[0];
      },
      sortByFormatted: false,
    },
    {
      key: 'username',
    },
    {
      key: 'password',
    },
    {
      key: 'remoteIP',
      sortable: true,
    },
    {
      key: 'country',
    },
  ];

  onSubmit(event: Event) {
    event.preventDefault();

    console.log(this.searchString);
    const resp = Axios.get<LoginAttempt[]>(
      `/api/query?query=${this.searchString}`,
    );

    resp.then((r) => {
      this.attempts = r.data;
    });
  }
}
