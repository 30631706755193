































import { Component, Vue } from 'vue-property-decorator';
import AttemptList from '@/components/AttemptList.vue';
import Stats from '@/components/Stats.vue';
import Home from '@/components/Home.vue';

@Component({
  components: {
    AttemptList,
    Stats,
    Home,
  },
})
export default class App extends Vue {}
