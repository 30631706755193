import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AttemptList from '@/components/AttemptList.vue';
import Home from '@/components/Home.vue';
import Stats from '@/components/Stats.vue';
import SearchResult from '@/components/SearchResult.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fontsource/rubik';
import '@fontsource/secular-one';
import App from './App.vue';

Vue.config.productionTip = false;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    alias: '/home',
  },
  {
    path: '/attempts',
    name: 'Attempt List',
    component: AttemptList,
    props: {
      // items: testAttempts,
    },
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchResult,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
