















import { Component, Prop, Vue } from 'vue-property-decorator';
import { BvTableFieldArray, BvTableFormatterCallback } from 'bootstrap-vue';
import { LoginAttempt } from '@/apiary/apiary';

@Component
export default class AttemptList extends Vue {
  @Prop() private items!: LoginAttempt[];

  attempts: LoginAttempt[];

  fields: BvTableFieldArray = [
    {
      key: 'date',
      sortable: true,
      formatter: (value: string): string => {
        const d = new Date(value);
        // This seems stupid...
        return d.toTimeString().split(' ')[0];
      },
      sortByFormatted: false,
    },
    {
      key: 'username',
    },
    {
      key: 'password',
    },
    {
      key: 'remoteIP',
      sortable: true,
    },
    {
      key: 'country',
    },
  ];

  constructor() {
    super();
    this.attempts = [];
  }

  mounted(): void {
    /**
    console.log(this);
    const at: LoginAttempt[] = [];

    for (let i = 0; i < 5; i += 1) {
      at.push(fakeAttempt());
    }

    setInterval(() => {
      at.push(fakeAttempt());
    }, 1000);
    */

    const attemptStream = new EventSource('/api/stream');
    attemptStream.addEventListener('message', (ev: MessageEvent<string>) => {
      const parsed: LoginAttempt = JSON.parse(ev.data);
      this.attempts.unshift(parsed);
    });
  }
}
