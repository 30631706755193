



































import { Component, Prop, Vue } from 'vue-property-decorator';
import StatsUsername from '@/components/StatsUsername.vue';
import StatsPie from '@/components/StatsPie.vue';
import Home from '@/components/Home.vue';

@Component({
  components: {
    StatsUsername,
    StatsPie,
    Home,
  },
})
export default class Stats extends Vue {}
