







































import { Component, Vue, Watch } from 'vue-property-decorator';
import axios from 'axios';

type TotalStatsHeaders =
  | 'UniquePasswords'
  | 'UniqueUsernames'
  | 'UniqueIPs'
  | 'UniqueCountries'
  | 'TotalLoginAttempts';

interface TotalStatsResult {
  name: TotalStatsHeaders;
  count: number;
}

@Component
export default class Home extends Vue {
  totalLoginAttempts: number;

  uniquePasswords: number;

  uniqueUsernames: number;

  uniqueIPs: number;

  uniqueCountries: number;

  private updaterHandle?: number;

  ready = false;

  constructor() {
    super();
    this.totalLoginAttempts = 0;
    this.uniquePasswords = 0;
    this.uniqueUsernames = 0;
    this.uniqueIPs = 0;
    this.uniqueCountries = 0;
  }

  updateStats(): void {
    const url = `/api/stats?type=total`;
    axios
      .get<TotalStatsResult[]>(url)
      .then((resp) => {
        const totals = resp.data.find((e) => e.name === 'TotalLoginAttempts')
          ?.count;
        if (totals) {
          this.totalLoginAttempts = totals;
        }

        const passwords = resp.data.find((e) => e.name === 'UniquePasswords')
          ?.count;
        if (passwords) {
          this.uniquePasswords = passwords;
        }

        const usernames = resp.data.find((e) => e.name === 'UniqueUsernames')
          ?.count;
        if (usernames) {
          this.uniqueUsernames = usernames;
        }

        const ips = resp.data.find((e) => e.name === 'UniqueIPs')?.count;
        if (ips) {
          this.uniqueIPs = ips;
        }

        const countries = resp.data.find((e) => e.name === 'UniqueCountries')
          ?.count;
        if (countries) {
          this.uniqueCountries = countries;
        }

        this.ready = true;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  created(): void {
    this.updateStats();

    this.updaterHandle = setInterval(() => {
      this.updateStats();
    }, 5000);
  }

  beforeDestroy(): void {
    clearInterval(this.updaterHandle);
  }
}
