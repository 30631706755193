









import { Component, Prop, Vue } from 'vue-property-decorator';
import { StatResult } from '@/apiary/apiary';
import axios from 'axios';
import Chart from 'chart.js/auto';
import randomColor from 'randomcolor';

@Component
export default class StatsUsername extends Vue {
  @Prop() private msg!: string;

  stats: StatResult[];

  constructor() {
    super();
    this.stats = [];
  }

  mounted(): void {
    axios
      .get<StatResult[]>('/api/stats?type=username&limit=10')
      .then((resp) => {
        this.stats = resp.data;
        this.renderPie();
      });
  }

  renderPie(): void {
    const elem = document.getElementById('chart') as HTMLCanvasElement;
    const ctx = elem.getContext('2d') as CanvasRenderingContext2D;
    const sortedStats = this.stats.sort();
    const values = sortedStats.map((s) => s.count);
    const headers = sortedStats.map((s) => s.name);
    const colors = sortedStats.map(() => randomColor());

    const chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: headers,
        options: {},
        datasets: [
          {
            data: values,
            backgroundColor: colors,
          },
        ],
      },
    });
  }
}
